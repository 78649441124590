// disables access to the modules that require authentication

export default defineNuxtRouteMiddleware(async to => {

    const customer = useCustomer();
    const appConfig = useAppConfig();
    const pageData = usePage().value.page;
    const cache = useCache();

    const modulesRequiringAuthentication = [
        'account-details',
        'account-orders',
        'account-rewards',
        'account-settings'
    ];

    const moduleName = appConfig.moduleMapping[pageData?.module.id];

    // redirect to login page if the user is not logged in and the module requires authentication
    if (!customer.isLogged() && modulesRequiringAuthentication.includes(moduleName)) {

        const urlToRedirect = cache.getModuleUrl('modules/login');

        return navigateTo(urlToRedirect);
    }
})