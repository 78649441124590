import { callWithNuxt } from 'nuxt/app';

export const useApiFetch = async (url: String, options: Object) => {

  const app = useNuxtApp();
  const customer = useCustomer();

  if (!options) {
    options = {};
  }

  options.headers = options.headers || {};
  options.headers['x-real-host'] = useRequestURL().host;

  await callWithNuxt(app, async () => {
    
    const customerTokens = await customer.checkOrUpdateActiveToken();

    if (!!customerTokens.activeToken && !!customerTokens.activeFingerprint && !!customerTokens.refreshToken) {
      options.headers['Cookie'] = `activeToken=${customerTokens.activeToken}; activeFingerprint=${customerTokens.activeFingerprint}`;
    }

    if (useCookie('cacheDisabled').value) {
      if (options.headers['Cookie']) {
        options.headers['Cookie'] += '; ';
      }
      else {
        options.headers['Cookie'] = '';
      }

      options.headers['Cookie'] += `${useShopZone().value.domain}-cacheDisabled=true`;
    }
  });

  const start = new Date();
  //console.log(`--- API start ${url}`);


  const result = await $fetch(url, options);

  //console.log(result);
  //console.log(`API fetch ${url} took ${new Date().getTime() - start.getTime()}ms`);

  try {
    useState('serverTiming', () => []).value.push({ started: start.getTime(), description: url.replace('/api/', 'API: '), duration: new Date().getTime() - start.getTime() });
  }
  catch (e) {
    //console.error(e);
  }

  return result;
}