import { useUtils } from './useUtils'

export const useLog = () => {

  const logError = async (exception: Error, customDataObject: Array<object>) => {

    const appConfig = useAppConfig();

    let eventId;

    if (exception.name?.startsWith('Cannot find static asset') || exception.message?.startsWith('Cannot find static asset') || exception.message?.includes('Failed to fetch dynamically imported module')) return;

    if (process.env.NODE_ENV === 'production') {
      try {

        eventId = await $fetch(appConfig.errorLogging.serviceEndpointUrl, {
          method: 'POST',
          body: {
            Subject: `${process.server ? 'Server' : 'Client'}` + ': ' + `${(exception.name ? (exception.name + ': ') : '') + exception.message}`,
            Severity: 3,
            Text: `
          <h2>${process.server ? 'Server' : 'Client'} error</h2>
          <hr />
          <h3>Exception data</h3>
          <table>
              ${Object.keys(exception).map(key => `<tr><td><strong>${key}</strong></td><td>${typeof exception[key] === 'object' ? JSON.stringify(exception[key]) : exception[key]}</td></tr>`).join('\n')}
          </table>
          ${customDataObject ? `
          <hr />
          <h3>Custom data</h3>
          <table>
              ${Object.keys(customDataObject).map(key => `<tr><td><strong>${key}</strong></td><td>${typeof customDataObject[key] === 'object' ? JSON.stringify(customDataObject[key]) : customDataObject[key]}</td></tr>`).join('\n')}
          </table>` : ''}
          ${process.client ? `
          <hr />
          <h2>Session data</h2>
          <table>
              <tr><td><strong>URL</strong></td><td>${document.location}</td></tr>
              <tr><td><strong>Cookie</strong></td><td>${document.cookie}</td></tr>
              <tr><td><strong>Cart</strong></td><td>${typeof localStorage === 'object' ? localStorage.cart : ''}</td></tr>
              <tr><td><strong>Browser</strong></td><td>${navigator.userAgent}</td></tr>
              <tr><td><strong>Language</strong></td><td>${navigator.language}</td></tr>
              <tr><td><strong>Languages</strong></td><td>${navigator.languages.join(', ')}</td></tr>` : ''}
          </table>`
          },
          headers:
          {
            'x-api-key': appConfig.errorLogging.apiKey
          }
        }).idEvent;
      }
      catch (ex) {
        console.error('Error logging failed', ex);
      }
    }
    else {
      useUtils().logError(JSON.stringify({ exception, customDataObject }, null, 2));
    }

    return eventId;
  }

  return {
    logError
  }
}