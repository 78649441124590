// fetches page data and saves it to the state for all components
// page data retrieval is executed only on path change - it ignores query that is managed/watched by the component itself

import { useShopZone } from "~/composables/useShopZone";
import { useCustomer } from "../composables/useCustomer";
import { useApiFetch } from "../composables/useApiFetch";
import { callWithNuxt } from 'nuxt/app';

export default defineNuxtRouteMiddleware(async (to, from) => {

    // only hash has been changed - it is used for filtering purposes, not for routing, so cancel data refetching
    if (from.path === to.path && JSON.stringify(from.query) === JSON.stringify(to.query) && (from.hash != to.hash)) {
        return;
    }

    const nuxtApp = useNuxtApp();

    if (process.client) {
        if (to.query.cache === 'false') {
            useShopCookie().set('cacheDisabled', 'true');
        }
        else if (to.query.cache === 'true') {
            useShopCookie().remove('cacheDisabled');
        }
    }

    // disable double fetching on regular hydratation on the client with the state from the server
    if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
        return;
    }

    useState('serverTiming', () => []).value.push({ started: new Date().getTime(), description: 'Page Load Started' });

    let shopZone;

    if (!useShopZone().value) {
        useShopZone().value = useShopRouting().getShopZone(useRequestURL().host)!;
    }

    shopZone = useShopZone().value;

    const locale = useLocale().getLocale();
    const pageData = usePage();
    const cache = useCache();
    const appConfig = useAppConfig();

    await useCustomer().initializeCustomer();

    let url = to.path.substring(1) || 'home';

    // initial load or path change
    if (!pageData.value || pageData.value.url !== url) {

        const loadTopBar = (async (app) => {
            await callWithNuxt(app, async () => {
                if (!useState('topBarData').value) {
                    useState('topBarData').value = (await useApiFetch(`/api/topBar?languageId=1`)).topBar
                }
            });
        });

        const loadGroups = (async (app) => {
            await callWithNuxt(app, async () => {
                if (!useState('groups').value) {
                    useState('groups').value = (await useApiFetch(`/api/groups?languageId=${locale.languageId}&currencyId=${locale.currencyId}&parentIds=${appConfig.primaryNav.parentGroupId}`)).groups;
                }
            });
        });


        const loadPageUrlsByIds = (async (app) => {
            await callWithNuxt(app, async () => {
                if (!useState('cachedPageUrls').value) {
                    const pageIdsToFetch = appConfig.cache.pageIds;
                    const pagesData = (await useApiFetch(`/api/pageUrlsByIds?pageIds=${pageIdsToFetch}&languageId=${locale.languageId}&currencyId=${locale.currencyId}`)).pageById;

                    let pagesObj = {};

                    pagesData.forEach(item => {
                        pagesObj[item.id] = item.url;
                    });

                    useState('cachedPageUrls').value = pagesObj;
                }
            });
        });

        const loadModuleUrls = (async (app) => {
            await callWithNuxt(app, async () => {
                if (!useState('cachedModuleUrls').value) {
                    const moduleIdsToFetch = appConfig.cache.moduleIds;
                    const modulesData = (await useApiFetch(`/api/pageUrlsByModuleIds?moduleIds=${moduleIdsToFetch}&languageId=${locale.languageId}&currencyId=${locale.currencyId}`)).getFirstPageByIdModules;

                    let modulesObj = {};

                    modulesData.forEach(item => {
                        modulesObj[item.module.name] = item.url;
                    });

                    useState('cachedModuleUrls').value = modulesObj;
                }
            });
        });

        const loadPage = (async (app) => {
            await callWithNuxt(app, async () => {
                pageData.value = await useApiFetch(`/api/page?url=${encodeURIComponent(url)}&languageId=${locale.languageId}&currencyId=${locale.currencyId}${to.query.hs && to.query.key ? `&hs=${to.query.hs}&key=${to.query.key}` : ''}`);
            });
        });

        const loadFreeDays = (async (app) => {
            await callWithNuxt(app, async () => {
                await useAvailabilityUtils().loadFreeDays();
            });
        });

        const app = useNuxtApp();

        await Promise.all([loadTopBar(app), loadGroups(app), loadPageUrlsByIds(app), loadModuleUrls(app), loadPage(app), loadFreeDays(app)]);

        if (pageData.value.page?.entity?.__typename === 'AbandonedUrl') {
            return navigateTo(pageData.value.page.entity.redirectTo, { redirectCode: 301 });
        }

        if (pageData.value.error) {
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true });
        }

        // close all dialogs, enable page scrolling
        if (process.client) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
            useDialog().closeAll();
        }

        //setHeader(useRequestEvent(), 'X-Frame-Options', 'SAMEORIGIN');
    }
});