export default defineNuxtRouteMiddleware(async (to, from) => {

  // if executed on server or only hash has been changed (it is used for filtering purposes, not for routing), do not trigger analytics route changed event
  if (import.meta.server || (from.path === to.path && JSON.stringify(from.query) === JSON.stringify(to.query) && (from.hash != to.hash))) {
    return;
  }

  if (process.client) {

    const pageData = usePage().value.page;

    if (pageData) {

      const locale = useLocale().getLocale();
      const shopZone = useShopRouting().getShopZone(useRequestURL().host);

      let eventData = {
        site: shopZone?.domain,
        page_id: pageData.id,
        page_category: pageData.module.pageCategoryLabel,
        page_type: pageData.module.pageTypeLabel,
        page_location: `${document.location.origin}${to.fullPath}`,
        page_title: pageData.title,
        currency: locale.currency.sign,
        language: locale.locale
      }

      if (pageData.entity?.zboziCategoryPath?.length) {
        eventData.zbozi_category_path = pageData.entity.zboziCategoryPath[0].value;
      }

      if (useCustomer().isLogged()) {

        const customer = useCustomer().currentCustomer().value;

        eventData = Object.assign(eventData, {
          user_id: customer.id,
          user_email: customer.email,
          user_type: 'Zákazník',
          user_level: customer.level.name,
          user_loyalty_program: customer.level.salePercent === 0 ? 'Žádná sleva' : (customer.level.lower ? 'Vyšší sleva' : 'Nižší sleva'),
          user_country: customer.shippingAddress?.country?.name,
          user_city: customer.shippingAddress?.city,
          user_order: customer.level.totalOrders,
          user_last_order: customer.level.recentOrderDate,
          user_total_revenue: customer.level.totalTurnoverWithoutVat,
        });
      }

      useBaseAnalytics().push(eventData);

      useConsent().propagateDefaultCMP();

      if (useConsent().getConsent().value != null) {
        useConsent().updateCMP();
      }

      useBaseAnalytics().pushEvent('routeChanged');
    }
  }

});