import { useShopRouting } from './useShopRouting'
import { useShopZone } from './useShopZone'

export function useShopCookie() {

    const getFullCookieName = (cookieName: String) => `${useShopZone().value.domain}-${cookieName}`;

    const read = (cookieName: String) => {

        const fullName = getFullCookieName(cookieName);
        const cookie = useCookie(fullName);

        if (typeof cookie !== 'undefined') {
            return cookie.value;
        }
        else {
            return null;
        }
    }

    const set = (cookieName: String, value: String, expirationInSeconds: Number | undefined, secure: Boolean | undefined) => {

        const fullName = getFullCookieName(cookieName);
        var expires = "";

        var date = new Date();
        date.setTime(date.getTime() + (expirationInSeconds ?? (30 * 24 * 60 * 60)) * 1000);
        expires = "; expires=" + date.toUTCString();

        document.cookie = fullName + "=" + (value || "") + (secure ? "; Secure;" : "") + expires + "; path=/";
    }

    const remove = (cookieName: String) => {
        const fullName = getFullCookieName(cookieName);

        document.cookie = fullName + '=; Max-Age=-99999999;';
    }

    return {
        read,
        set,
        remove,
        getFullCookieName
    }
}