
export const useLocale = () => {

    const getLocale = () => {

        const appConfig = useAppConfig();
        const shopZone = useShopZone().value;

        if (useCookie('selectedCurrency').value) {
            shopZone.currency = useCookie('selectedCurrency').value;
        }
        
        const result = {
            locale: shopZone?.locale,
            language: shopZone?.language,
            languageId: appConfig.languages[shopZone?.language],
            currency: appConfig.currencies[shopZone?.currency],
            currencyId: appConfig.currencies[shopZone?.currency].id
        };

        return result;
    };

    const setCurrency = (currency) => {
        useCookie('selectedCurrency').value = currency;
        document.location.reload();
    }

    const formatPrice = (value: number, freeLabelAllowed: Boolean = false, localeObj: Object = undefined, omitCurrencySign: Boolean = false, maxDigitsOverride: Number = undefined) => {
        if (value === 0 && freeLabelAllowed) {
            return useI18n().t('Zdarma');
        }

        localeObj = localeObj ?? getLocale();

        const formatter = new Intl.NumberFormat(localeObj.currency.locale, {
            style: omitCurrencySign ? undefined : 'currency',
            currency: localeObj.currency.sign,
            minimumFractionDigits: localeObj.currency.minDigits,
            maximumFractionDigits: maxDigitsOverride ?? localeObj.currency.maxDigits,
            currencySign: 'accounting'
        });

        const rawResult = formatter.format(value);
        let result;

        if (localeObj.currency.signBefore) {
            result = rawResult.replace(localeObj.currency.signLocal, localeObj.currency.signLocal + ' ').trim();
        }
        else {
            result = rawResult;
        }

        return result;
    }

    return {
        getLocale,
        setCurrency,
        formatPrice
    }
}