export const useWareAnalytics = () => {

  const itemsData = useState('analyticsWareData', () => []);

  const processAnalyticsItem = (item) => {

    const appConfig = useAppConfig();
    const utils = useUtils();
    const badges = useWareBadges();

    item.properties = item.properties.concat(item.customProperties);

    const wareBadges = badges.getBadgesForWare(item);

    const itemData = {
      item_id: item.id,
      item_name: item.name,
      item_url: item.page.url,
      item_image: item.image?.id ? utils.getWareImageUrl(item.image?.id, item.name, 'or') : undefined,
      item_brand: item.properties?.find(q => q.id === appConfig.parameterIds.vendor)?.values?.[0]?.value,
      item_warranty: item.properties?.find(q => q.id === appConfig.parameterIds.warranty)?.values?.[0]?.value ?? '24 měsíců',
      item_availability: item.stored ? 'Skladem' : 'Není skladem',
      item_tags: wareBadges.map(badge => badge.analyticsText).sort((a, b) => a.localeCompare(b)).join(','),
      price: item.price.minPrice.priceWithoutVat
    }

    item.groups.filter(group => group.group.parents.some(parentGroup => parentGroup.id === appConfig.groupIdDleDruhu)).sort((a, b) => a.group.priority - b.group.priority).forEach((item, index) => {
      if (index < 5) {
        const key = 'item_category' + (index > 0 ? `_${(index + 1)}` : '');
        itemData[key] = item.group.parents.sort((a, b) => b.level - a.level).flatMap(parentGroup => parentGroup.name).slice(1).join(' / ');
      }
    });

    let result;

    if (item.variants?.length === 1) {

      const variant = item.variants[0];

      const variantData = {
        item_variant_id: variant.id,
        item_variant_size: variant.properties?.find(q => q.id === appConfig.parameterIds.size || q.id === appConfig.parameterIds.size1)?.values?.[0]?.value,
        item_variant_color: variant.properties?.find(q => q.id === appConfig.parameterIds.color)?.values?.[0]?.value,
        item_variant_price: variant.prices.baseWithoutVat
      }

      result = {
        variantId: variant.id,
        data: Object.assign(itemData, variantData)
      };
    }
    else {
      result = {
        wareId: item.id,
        data: itemData
      };
    }

    return result;
  }

  const fetchDataForItems = async (wareIds, variantIds, skipVisibilityCheck) => {

    const locale = useLocale().getLocale();
    const utils = useUtils();

    let waresToFetch = [];
    const variantsToFetch = [];

    wareIds?.forEach(wareId => {
      if (!itemsData.value.find(item => item.wareId === wareId)) {
        waresToFetch.push(wareId);
      }
    });

    variantIds?.forEach(variantId => {
      if (!itemsData.value.find(item => item.variantId === variantId)) {
        variantsToFetch.push(variantId);
        waresToFetch.push(...wareIds);
      }
    });

    waresToFetch = utils.removeDuplicatesFromArray(waresToFetch);

    if (waresToFetch.length) {
      const dataForItems = (await useApiFetch(`/api/wareAnalytics?wareIds=${waresToFetch.join(',')}&variantIds=${variantsToFetch.join(',')}${skipVisibilityCheck ? '&skipVisibilityCheck=true' : ''}&languageId=${locale.languageId}&currencyId=${locale.currencyId}`)).wareList.wares.items;

      const processedItems = dataForItems.flatMap(ware =>
        ware.variants?.length ? ware.variants.map(variant => processAnalyticsItem({ ...ware, variants: [variant] })) : [processAnalyticsItem(ware)]
      );

      processedItems.forEach(item => {
        if (!itemsData.value.some(q => q.wareId === item.wareId)) {
          itemsData.value.push(item);
        }
        else if (!itemsData.value.some(q => q.variantId === item.variantId)) {
          itemsData.value.push(item);
        }
      });
    }
  }

  const getItems = async (wareIds: Array<Number>, variantIds: Array<Number> | undefined, variantQuantities: Array<object> | undefined, skipVisibilityCheck: Boolean | undefined) => {

    await fetchDataForItems(wareIds, variantIds, skipVisibilityCheck);

    const result = itemsData.value.filter(item => {

      if (variantIds?.length) {
        return variantIds.includes(item.variantId);
      }
      else if (wareIds?.length) {
        return wareIds.includes(item.wareId);
      }

    }).map(item => {

      if (variantQuantities?.some((vq) => vq.variantId === item.variantId)) {
        item.data.quantity = variantQuantities.find(q => q.variantId === item.variantId).quantity;
      }

      return item.data;
    });

    return result;
  }

  return {
    getItems
  }
}