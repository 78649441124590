export const useDialog = () => {

  const dialog = useState('dialog', () => { return [] });

  const open = (name, data) => {

    // If dialog is already open, don't open it again
    if (dialog.value.some(dialog => dialog.name === name)) {
      return;
    }

    const dialogObj = {
      name: name,
      isOpen: true,
      isMessageContainer: false
    };

    if (data) {
      dialogObj.data = data;
    }

    // if there is no dialog open, set it as the message container
    if (!dialog.value.length) {
      dialogObj.isMessageContainer = true;
    }

    dialog.value.push(dialogObj);

    useBaseAnalytics().pushEvent('view_modal', {
      modal_name: name
    });
  };

  const close = (name) => {

    // if name is provided, close the dialog with that name
    if (name) {
      const dialogToClose = dialog.value.filter(dialogInstance => dialogInstance.name === name)[0];
      dialogToClose.isOpen = false;

      setTimeout(() => {
        dialog.value.splice(dialog.value.indexOf(dialogToClose), 1);
      }, 300);
    }

    // if no name is provided, close the most recent dialog
    else {
      const openDialogs = dialog.value.filter(dialogInstance => dialogInstance.isOpen);

      const mostRecentDialog = openDialogs[openDialogs.length - 1];

      mostRecentDialog.isOpen = false;

      setTimeout(() => {
        dialog.value.pop();
      }, 300);
    }
  };

  const closeAll = () => {
    dialog.value.forEach(dialogInstance => { 
      close(dialogInstance.name);
    });
  }

  const get = (name) => computed(() => dialog.value).value.find((dialog) => dialog.name === name);

  return {
    get,
    open,
    close,
    closeAll
  };
}