export function useShopRouting() {

    const getShopZone = (host: string) => {
        const appConfig = useAppConfig();

        const domain = host?.split(':')[0];

        let shopZone;

        if (domain) {
            // shop zone based on domain
            shopZone = appConfig.shopZones.find(q => q.domain === domain);

            // debug domain
            if (!shopZone) {
                shopZone = appConfig.shopZones.find(q => q.devDomain === domain);
                if (typeof useState !== 'undefined') {
                    useState('isDev').value = true;
                }
            }
        }
        
        // default shop zone
        if (!shopZone) {
            shopZone = appConfig.shopZones.find(q => q.domain === appConfig.defaultShopZone);
            
            if (typeof useState !== 'undefined') {
                useState('isDev').value = true;
            }
        }

        return shopZone;
    }

    const getCurrentHash = () => {
        let result = '';
        let hash, escapedFragment;

        hash = useRoute().hash;
        escapedFragment = useRoute().query._escaped_fragment_;

        if (hash) {
            result = hash;
        }
        else if (escapedFragment) {
            result = decodeURIComponent(escapedFragment);
        }

        return result;
    }

    const parseHash = (hash: String | undefined | null = null) => {

        const result = [];

        const usedHash = hash || getCurrentHash();

        usedHash.replace('#!', '').split('&').forEach(param => {
            const parts = param.split('=');

            if (parts[0]) {
                const value = parts[1]?.split(',');
                result.push({ key: parts[0], value: parts[1] ? value : [] });
            }
        });

        return result;
    }

    const updateValueInHash = (key: String, value: String, removeValue: Boolean = false, replaceValue: Boolean = false, hash: String | undefined | null = null) => {

        const hashObj = parseHash(hash);
        const item = hashObj.find(q => q.key === key);

        value = value?.toString();

        // key in hash exists, handle value only
        if (item) {

            if ((!item.value && !removeValue) || replaceValue) {
                item.value = [];
            }

            const indexOfValue = item.value.indexOf(value);

            // remove, value exists
            if (indexOfValue > -1 && (removeValue || (!removeValue && !replaceValue))) {
                item.value.splice(indexOfValue, 1);

                if (item.value.length === 0) {
                    hashObj.splice(hashObj.indexOf(item), 1);
                }
            }
            // replace, value exists
            else if (indexOfValue > -1 && !removeValue && replaceValue) {
                item.value[indexOfValue] = value;
            }
            // add, value doesn´t exist
            else if (indexOfValue === -1 && !removeValue) {
                item.value.push(value);
            }
        }
        // add new key or replace it with the given value
        else {
            hashObj.push({ key: key, value: [value] });
        }

        return hashObj.filter((item) => item.value.length > 0);
    }

    const stringifyHash = (hashObj: Array<object>) => {

        const result = hashObj.map((item) => `${item.key}=${item.value?.sort().join(',')}`).sort().join('&');

        return `#!${result}`;
    }

    const pushHash = (hashObj: Array<object>) => {
        const hash = stringifyHash(hashObj);
        useRouter().push(hash);
    }

    return {
        getShopZone,
        stringifyHash,
        parseHash,
        pushHash,
        updateValueInHash
    }
}