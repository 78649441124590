export const useCache = () => {

  const getPageUrl = (id: number) => {
    const result = useState('cachedPageUrls').value[id];

    return result;
  }

  const getModuleUrl = (moduleName: number) => {
    const result = useState('cachedModuleUrls').value[moduleName];

    return result;
  }

  return {
    getPageUrl,
    getModuleUrl
  }
}