import { useBreakpoints, useElementBounding } from '@vueuse/core';

export default defineNuxtPlugin(nuxtApp => {

  const appConfig = useAppConfig();
  const view = useBreakpoints(appConfig.breakpoints);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

  window.gtag = gtag;

  setTimeout(() => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.defer = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', useShopRouting().getShopZone(useRequestURL().host).gtmContainerKey);
  }, view.greaterOrEqual('desktop').value ? 1500 : 3000);
});