export default defineNuxtPlugin((nuxtApp) => {

  if (!process.env.NODE_ENV !== 'production') { 
    return;
  }

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {

    const errorObject = {
      name: 'Nuxt app error handler',
      message: error?.message,
      location: process.client ? document.location : '',
      stack: error?.stack
    }

    useLog().logError(errorObject);
  }

  nuxtApp.hook('vue:error', (error, instance, info) => {

    const errorObject = {
      name: 'Nuxt app error handler',
      message: error?.message,
      location: process.client ? document.location : '',
      stack: error?.stack
    }

    useLog().logError(errorObject);
  })

  nuxtApp.hook('app:error', (error) => {

    const errorObject = {
      name: 'app:error handler',
      message: error?.message,
      location: process.client ? document.location : '',
      stack: error?.stack
    }

    useLog().logError(errorObject);
  })

})
