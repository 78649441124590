// initializes cart and fetches total price from the backend

export default defineNuxtRouteMiddleware(async to => {

    const appConfig = useAppConfig();

    if (process.client) {

        // when loading thankyou, clear cart items

        if (usePage().value?.page?.module.id === 16) {
            await useCart().clear();
        }

        const currentCartItems = useCart().items.value;
        const cookieCartItems = useCart().getItems();

        // cart items are not loaded, have changed from the other browser tab or the cookie has expired, reload cart items

        if (!useUtils().isDeepEqual(currentCartItems, cookieCartItems)) {
            const reloadPromise = useCart().reloadItems();

            if (appConfig.moduleIdsToAwaitCartLoaded.includes(usePage().value?.page?.module.id)) {
                await reloadPromise;
            }
        }
    }
})