import { default as _91_46_46_46slug_93MjUkU92cjRMeta } from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/[...slug].vue?macro=true";
import { default as exit_46aspxewG7fS7ElcMeta } from "C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/exit.aspx.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93MjUkU92cjRMeta?.name ?? "slug___cs",
    path: _91_46_46_46slug_93MjUkU92cjRMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93MjUkU92cjRMeta || {},
    alias: _91_46_46_46slug_93MjUkU92cjRMeta?.alias || [],
    redirect: _91_46_46_46slug_93MjUkU92cjRMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MjUkU92cjRMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93MjUkU92cjRMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93MjUkU92cjRMeta || {},
    alias: _91_46_46_46slug_93MjUkU92cjRMeta?.alias || [],
    redirect: _91_46_46_46slug_93MjUkU92cjRMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MjUkU92cjRMeta?.name ?? "slug___sk",
    path: _91_46_46_46slug_93MjUkU92cjRMeta?.path ?? "/sk/:slug(.*)*",
    meta: _91_46_46_46slug_93MjUkU92cjRMeta || {},
    alias: _91_46_46_46slug_93MjUkU92cjRMeta?.alias || [],
    redirect: _91_46_46_46slug_93MjUkU92cjRMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: exit_46aspxewG7fS7ElcMeta?.name ?? "exit.aspx___cs",
    path: exit_46aspxewG7fS7ElcMeta?.path ?? "/exit.aspx",
    meta: exit_46aspxewG7fS7ElcMeta || {},
    alias: exit_46aspxewG7fS7ElcMeta?.alias || [],
    redirect: exit_46aspxewG7fS7ElcMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/exit.aspx.vue").then(m => m.default || m)
  },
  {
    name: exit_46aspxewG7fS7ElcMeta?.name ?? "exit.aspx___en",
    path: exit_46aspxewG7fS7ElcMeta?.path ?? "/en/exit.aspx",
    meta: exit_46aspxewG7fS7ElcMeta || {},
    alias: exit_46aspxewG7fS7ElcMeta?.alias || [],
    redirect: exit_46aspxewG7fS7ElcMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/exit.aspx.vue").then(m => m.default || m)
  },
  {
    name: exit_46aspxewG7fS7ElcMeta?.name ?? "exit.aspx___sk",
    path: exit_46aspxewG7fS7ElcMeta?.path ?? "/sk/exit.aspx",
    meta: exit_46aspxewG7fS7ElcMeta || {},
    alias: exit_46aspxewG7fS7ElcMeta?.alias || [],
    redirect: exit_46aspxewG7fS7ElcMeta?.redirect,
    component: () => import("C:/BuildAgent/work/546b3c95a3861166/b2c/b2c-nuxt/pages/exit.aspx.vue").then(m => m.default || m)
  }
]