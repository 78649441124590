export function useAvailabilityUtils() {

  const utils = useUtils();

  const skipDayOnMissedDeadline = (dateTime: Date, deadlineTime: Date): Date => {
    let result = dateTime;

    const todayDeadline = new Date();
    todayDeadline.setHours(deadlineTime.getHours(), deadlineTime.getMinutes(), deadlineTime.getSeconds(), deadlineTime.getMilliseconds());

    if (new Date().getTime() > todayDeadline.getTime()) {
      result = utils.addDaysToDate(result, 1);
    }

    result = utils.extractDate(result);

    return result;
  }

  const skipFreeDays = (dateFrom: Date, numberOfWorkingDays: number, freeDays: Array<Date>): Date => {

    let result = getSameOrNextWorkingDay(dateFrom, freeDays);

    for (let i = 0; i < numberOfWorkingDays; i++) {
      result = utils.addDaysToDate(result, 1);
      result = getSameOrNextWorkingDay(result, freeDays);
    }

    return result;
  }

  const getSameOrNextWorkingDay = (dateFrom: Date, freeDays: Array<Date>, deadlineTime: Date | undefined = undefined) => {

    let result = dateFrom;

    if (typeof deadlineTime !== 'undefined') {
      result = skipDayOnMissedDeadline(result, deadlineTime);
    }

    while (!isWorkingDay(result, freeDays)) {
      result = utils.addDaysToDate(result, 1);
    }

    result = utils.extractDate(result);

    return result;
  }

  const isWorkingDay = (date: Date, freeDays: Array<Date>) => {
    const result = date.getDay() !== 0 && date.getDay() !== 6 && (!freeDays || !freeDays.some(item => item.valueOf() === date.valueOf()));

    return result;
  }

  const getFilteredFreeDays = (type, parameter): Array<Date> => {
    const result = useState('freeDays').value.filter(item => {
      if (typeof type !== 'undefined') {
        if (item.idType === type) {
          if (typeof parameter !== 'undefined') {
            if (item.parameter === parameter) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return true;
          }
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }
    }).map(item => new Date(item.date));

    return result;
  }

  const getFreeDaysForOrderProcessing = (storeId: number | undefined) => {
    let result = [];

    // add all public free days
    result.push(...useState('freeDays').value.filter(freeDay => freeDay.idType === 0).map(freeDay => new Date(freeDay.date)));

    // add all company free days
    result.push(...useState('freeDays').value.filter(freeDay => freeDay.idType === 1).map(freeDay => new Date(freeDay.date)));

    // add store specific free days
    if (typeof storeId === 'number') {
      result.push(...useState('freeDays').value.filter(freeDay => freeDay.idType === 2 && freeDay.parameter === storeId).map(freeDay => new Date(freeDay.date)));
    }

    result = utils.removeDuplicatesFromArray(result);

    return result;
  }

  const getFreeDaysForTransport = () => {
    const result = getFilteredFreeDays(0);
    return result;
  }

  const loadFreeDays = async () => {
    if (!useState('freeDays').value) {

      useState('freeDays').value = (await useApiFetch(`/api/freeDays`)).freeDays;
    }
  }

  return {
    isWorkingDay,
    skipFreeDays,
    getFilteredFreeDays,
    getSameOrNextWorkingDay,
    getFreeDaysForOrderProcessing,
    getFreeDaysForTransport,
    loadFreeDays
  }
}