<template>
  <div class="my-12 text-lg text-center">
    <div class="mb-4 text-2xl font-bold">{{ i18n.t('500 - Chyba na stránce') }}</div>
    <p>{{ i18n.t('Na stránce se vyskytla chyba, o které byli informováni vývojáři. Zkuste prosím stránku načíst znovu. Děkujeme za pochopení.') }}</p>
    <p v-if="errorId" class="mt-4">{{ i18n.t('Pokud by se chyba opakovala, prosíme kontaktujte nás a sdělte ID chyby') }}: <strong>{{ errorId }}</strong></p>
    {{ error }}
  </div>
</template>
<script setup>

const i18n = useI18n();
const errorId = ref('');
const error = useError().value;

errorId.value = await useLog().logError(error);

</script>