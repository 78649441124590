export function useAvailability() {

  // short availability summary on wareListItem
  const getWareLabelForWareListItem = (isElectronicDistribution: boolean, isWareStoredAnywhere: boolean, isOverride30days: boolean, i18n: object) => {

    let result = '';

    if (isElectronicDistribution) {
      result = 'Ihned ke stažení';
    }
    else if (isWareStoredAnywhere) {
      result = 'Skladem';
    }
    else if (isOverride30days) {
      result = 'Na objednání';
    }

    return i18n.t(result);
  }

  // variant availability description on ware detail or in cart
  const getVariantLabel = (isElectronicDistribution: boolean, isPersonalPickupOnly: boolean, isStored: boolean, isStoredOnOwnStore: boolean, isPreorder: Date, isOverride30days: boolean, i18n) => {

    let result = '';

    if (isElectronicDistribution) {
      result = 'Ihned ke stažení';
    }
    else if (isStoredOnOwnStore) {
      if (isPersonalPickupOnly) {
        result = 'Skladem';
      }
      else {
        result = 'Ihned k odeslání';
      }
    }
    else if (isStored) {
      result = 'Na cestě';
    }
    else if (isPreorder) {
      result = 'Předobjednávka';
    }
    else if (isOverride30days) {
      result = 'Na objednání';
    }

    return i18n ? i18n.t(result) : result;
  }

  const getVariantDispatchDate = (isStored: boolean, isStoredOnOwnStore: boolean, deliveryPeriod: number, override30days: boolean, preorder: Date | undefined, fromStoreId: number | undefined, toStoreId: number | undefined, shippingDeadlineTime: Date | undefined, orderedServices: Array | undefined, dateFrom: Date | undefined = undefined) => {

    const aUtils = useAvailabilityUtils();
    const utils = useUtils();
    const appConfig = useAppConfig();

    const defaultSupplierId = appConfig.suppliers.find(supplier => supplier.isDefault)?.supplierId;

    if (typeof fromStoreId === 'undefined') {
      fromStoreId = defaultSupplierId;
    }

    let variantStockAvailabilityDate;

    // preorder
    if (!isStoredOnOwnStore && preorder && new Date(preorder).getTime() > new Date().getTime()) {
      variantStockAvailabilityDate = new Date(preorder);
    }

    // stored in the warehouse or in shops, ready to be shipped without any delay
    else if (isStoredOnOwnStore) {
      variantStockAvailabilityDate = utils.extractDate(new Date());
    }

    // stored by other supplier while the stuff needs to be ordered by us at first - take general deadline and free days into account
    else if (isStored) {
      const freeDaysForOrderProcessing = aUtils.getFreeDaysForOrderProcessing();

      variantStockAvailabilityDate = aUtils.getSameOrNextWorkingDay(dateFrom ?? utils.addDaysToDate(new Date(), deliveryPeriod ?? 0), freeDaysForOrderProcessing, getDeadlineTime());
    }

    else if (override30days) {
      variantStockAvailabilityDate = utils.addDaysToDate(new Date(), 30);
    }

    else {
      variantStockAvailabilityDate = utils.addDaysToDate(new Date(), 100);
    }

    let sourceStoreDispatchDate = variantStockAvailabilityDate;

    let deadlineTime;

    // no forwarding - direct dispatch from given store, apply deadline by the current shipping
    if (fromStoreId === toStoreId && shippingDeadlineTime) {
      const deadlineTimeString = shippingDeadlineTime;
      const deadlineDateString = new Date().toISOString().split('T')[0];
      const deadlineDateTimeString = `${deadlineDateString}T${deadlineTimeString}`;
      deadlineTime = new Date(deadlineDateTimeString);
    }
    // forwarding - apply general/internal deadline for order processing
    else {
      deadlineTime = getDeadlineTime();
    }

    // find the earliest possible dispatch date from the source store
    const freeDaysForDispatch = aUtils.getFreeDaysForOrderProcessing(fromStoreId);
    sourceStoreDispatchDate = aUtils.getSameOrNextWorkingDay(sourceStoreDispatchDate, freeDaysForDispatch, deadlineTime);

    let targetStoreArrivalDate = sourceStoreDispatchDate;

    // add possible forwarding delay - the data in appSettings are symmetric
    const forwardingDelay = appConfig.supplierForwardingDelays.find(item => (item.fromId === fromStoreId && item.toId === toStoreId) || (item.toId === fromStoreId && item.fromId === toStoreId)) ?? 0
    const freeDaysForIntake = aUtils.getFreeDaysForOrderProcessing(toStoreId);

    if (forwardingDelay) {
      targetStoreArrivalDate = aUtils.skipFreeDays(sourceStoreDispatchDate, forwardingDelay.days, freeDaysForIntake);
    }

    let variantDispatchDate = targetStoreArrivalDate;

    if (orderedServices) {

      // group ordered services for this item by the service type and apply delay for each group sequentially
      const serviceIds = useUtils().removeDuplicatesFromArray(orderedServices.map(service => service.serviceOption.service.id));
      // apply ordered services delay to the target store arrival date, assuming the services are processed by the target store, so free days for intake are used

      serviceIds.forEach(serviceId => {
        const delayForThisTypeOfService = orderedServices.filter(service => service.serviceOption.service.id === serviceId)[0].serviceOption.deliveryPeriod;
        variantDispatchDate = aUtils.skipFreeDays(variantDispatchDate, delayForThisTypeOfService, freeDaysForIntake);
      });
    }

    return variantDispatchDate;
  }

  const getVariantDeliveryDate = (dispatchDate: Date, shippingDays: number) => {

    const freeDaysForTransport = useAvailabilityUtils().getFreeDaysForTransport();
    const result = useAvailabilityUtils().skipFreeDays(dispatchDate, shippingDays, freeDaysForTransport);

    return result;
  }

  const getVariantDeliveryText = (deliveryDate: Date, forPickup: boolean, i18n: object) => {

    const dayOfWeek = deliveryDate.getDay();

    const formattedDate = `${deliveryDate.getDate()}.${deliveryDate.getMonth() + 1}.`;

    let result;

    if (forPickup) {
      result = i18n.t(`V [den v týdnu - ${dayOfWeek}] {0} může být připraveno`, [formattedDate]);
    }
    else {
      result = i18n.t(`V [den v týdnu - ${dayOfWeek}] {0} může být u Vás`, [formattedDate]);
    }

    return result;
  }

  const appendDatesToShippingLevels = async (shippingLevels: Array<object>, specificWareData: object) => {

    const utils = useUtils();
    const cart = useCart();

    const preferredSupplierIds = utils.removeDuplicatesFromArray(shippingLevels.map(shippingLevel => shippingLevel.shipping.idSupplierPreferred));

    // unique preferred supplier ids based on shipping levels
    const fulfillmentsByPreferredSuppliers = await getFulfillmentsByPreferredSuppliers(preferredSupplierIds, typeof specificWareData === 'undefined' ? cart.getInputCartItems() : [specificWareData]);

    // for each shipping level find latest dispatch date based on fulfillment data
    shippingLevels.forEach(shippingLevel => {

      // find fulfillment data for this shipping level
      const fulfillmentData = fulfillmentsByPreferredSuppliers.find(fulfillment => fulfillment.preferredSupplierId === shippingLevel.shipping.idSupplierPreferred);

      let latestDispatchDate;

      // go through all items dispatched by this preferred supplier
      fulfillmentData.items.forEach(item => {

        let itemData;

        // specific ware data is used for ware detail page or for electronic distribution dialog order
        if (typeof specificWareData !== 'undefined') {
          itemData = {
            stored: specificWareData.stored,
            storedOnOwnStore: specificWareData.storedOnOwnStore,
            deliveryPeriod: specificWareData.deliveryPeriod,
            override30days: specificWareData.override30days,
            preorder: specificWareData.preorder
          };
        }
        // cart data is used for cart
        else {
          itemData = cart.presentation.value.find(itemData => itemData.cartItemId === item.id);
        }

        item.storeFullfilments.forEach(storeFulfillment => {
          const dispatchDate = getVariantDispatchDate(itemData.stored, itemData.storedOnOwnStore, itemData.deliveryPeriod, itemData.override30days, itemData.preorder, storeFulfillment.idSupplier, shippingLevel.shipping.idSupplierPreferred, shippingLevel.dispatchDeadlineTime, itemData.orderedServices);

          if (typeof latestDispatchDate === 'undefined' || dispatchDate > latestDispatchDate) {
            latestDispatchDate = dispatchDate;
          }
        });

        // no store fulfillments - set dispatch date without store fullfilment delay
        if (typeof latestDispatchDate === 'undefined') {
          latestDispatchDate = getVariantDispatchDate(itemData.stored, itemData.storedOnOwnStore, itemData.deliveryPeriod, itemData.override30days, itemData.preorder, undefined, shippingLevel.shipping.idSupplierPreferred, shippingLevel.dispatchDeadlineTime, itemData.orderedServices);
        }
      });

      if (latestDispatchDate) {
        const deliveryDate = getVariantDeliveryDate(latestDispatchDate, shippingLevel.estimatedDeliveryDays);

        shippingLevel.dispatchDate = latestDispatchDate;
        shippingLevel.deliveryDate = deliveryDate;
      }

    });
  }

  const getFulfillmentsByPreferredSuppliers = async (preferredSupplierIds: Array<number>, cartItems: Array<object>) => {

    const locale = useLocale().getLocale();

    const result = await Promise.all(preferredSupplierIds.map(async preferredSupplierId => {
      const cartList = (await useApiFetch(`/api/cartListFulfillments?preferredSupplierId=${preferredSupplierId}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`, {
        method: 'POST',
        body: {
          items: cartItems
        }
      })).cartList;

      return { preferredSupplierId, items: cartList.items };
    }));

    return result;
  }

  const getDeadlineTime = (): Date => {
    const appConfig = useAppConfig();
    const result = new Date(appConfig.orderDeadlineTime);

    return result;
  }

  return {
    getWareLabelForWareListItem,
    getVariantLabel,
    getVariantDispatchDate,
    getVariantDeliveryDate,
    getVariantDeliveryText,
    appendDatesToShippingLevels
  }
}