export const useBaseAnalytics = () => {

  const push = (object) => {
    if (process.client && typeof dataLayer !== 'undefined') {
      dataLayer.push(object);
      //console.log(object);
    }
  }

  const pushEvent = (eventName: String, object: Object | undefined, callback: Function | undefined) => {

    let objectToPush;

    if (object) {
      objectToPush = Object.assign({ event: eventName, }, Object.assign(object, { _clear: true }));
    }
    else {
      objectToPush = {
        event: eventName,
        _clear: true
      };
    }

    if (typeof callback === 'function') {
      objectToPush.eventCallback = function (gtmId) {
        if (typeof gtmId === 'undefined' || gtmId.startsWith('GTM-')) {
          callback();
        }
      };

      objectToPush.eventTimeout = 2000;
    }

    push(objectToPush);
  }

  return {
    push,
    pushEvent
  };
}