import appConfig from "~/app.config";

export const useBusiness = () => {

  const getVariantSalePercent = (variant: any) => {
    if (variant.prices.recommended === 0) {
      return 0;
    }

    return Math.round((1 - variant.prices.base / variant.prices.recommended) * 100);
  }

  const isWarePersonalPickupOnly = (wareProperties: any) => {
    const result = wareProperties.some((property: any) => property.values.some((value: any) => value.lValue === appConfig.parameterlValues.internal_personalPickupOnly));

    return result;
  }

  const isWareElectronicDistribution = (ware: any) => {
    const result = ware.type === 'ELECTRONICDISTRIBUTION';

    return result;
  }

  const isWareOverride30days = (ware: any) => {
    const result = ware.tags.some((tag: any) => tag.type === 'OVERRIDE30DAYS');

    return result;
  }

  const isWarePriceOnRequest = (ware: any) => {
    const result = ware.sellType === 'REQUESTONLY';

    return result;
  }

  const isPreorder = (ware: any) => {
    const now = new Date();

    const result = !isNaN(ware.tags.find(tag => tag.type === 'PREORDER')?.value) && new Date(parseInt(ware.tags.find(tag => tag.type === 'PREORDER').value)).getTime() > now.getTime();

    return result;
  }

  return {
    getVariantSalePercent,
    isWarePersonalPickupOnly,
    isWareElectronicDistribution,
    isWareOverride30days,
    isWarePriceOnRequest,
    isPreorder
  }
}