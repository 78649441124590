import jwtDecode, { JwtPayload } from "jwt-decode";

export const useCustomer = () => {

    const currentCustomer = () => useState('customer', () => null);

    const acquireActiveToken = async () => {

        const refreshTokenCookie = useCookie('refreshToken');
        const refreshFingerprintCookie = useCookie('refreshFingerprint');

        const result = (await $fetch('/api/auth/active-token', {
            headers: {
                'Cookie': `refreshToken=${refreshTokenCookie.value}; refreshFingerprint=${refreshFingerprintCookie.value}`
            }
        }))?.customer?.getCustomerTokenUsingRefreshToken;
        
        const activeToken = result?.token;

        // the refresh token is invalid, clear both current active and refresh tokens
        if (!activeToken) {
            refreshTokenCookie.value = undefined;
            refreshFingerprintCookie.value = undefined;
        }

        return { activeToken: result?.token, activeFingerprint: result?.activeFingerprint };
    }

    const checkTokenValidity = (token: string) => {
        let tokenData;

        try {
            tokenData = jwtDecode<JwtPayload>(token);
        }
        catch {
            return false;
        }

        const expirationThresholdSeconds = 10;

        if (tokenData && (new Date().getTime() + expirationThresholdSeconds * 1000) < new Date(tokenData.exp! * 1000).getTime()) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkOrUpdateActiveToken = async () => {

        //return (await useNuxtApp().runWithContext(async () => {

        let activeToken = useCookie('activeToken');
        let refreshToken = useCookie('refreshToken');
        let activeFingerprint = useCookie('activeFingerprint');

        // when the customer has been logged and no active token is present or its validity has expired, acquire a new one
        if (!!refreshToken.value && (!activeToken.value || !checkTokenValidity(activeToken.value))) {

            const result = await acquireActiveToken();
            activeToken.value = result.activeToken;
            activeFingerprint.value = result.activeFingerprint;
        }

        return { activeToken: activeToken.value, activeFingerprint: activeFingerprint.value, refreshToken: refreshToken.value };
        //}));

    }

    const login = async (userName: string, password: string) => {

        try {
            const result = (await $fetch('/api/auth/login', {
                method: 'POST',
                body: {
                    userName: userName,
                    password: password
                }
            }));

            if (result.customer?.getCustomerToken?.token && result.customer?.getCustomerToken?.refreshToken) {

                useCookie('activeToken').value = result.customer.getCustomerToken.token;
                useCookie('refreshToken').value = result.customer.getCustomerToken.refreshToken;

                nextTick(async () => {
                    await initializeCustomer(true);
                    await useCart().mergeWithLoggedCustomersCart();
                });

                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            return false;
        }
    }

    const initializeCustomer = async (update) => {

        // fetch customer data only when explicitly requested (login, updating customer data in the account details) or when the current customer is not set (server side rendering)
        if (update || !currentCustomer().value) {
            const locale = useLocale().getLocale();
            const shopZone = useShopZone().value;

            const customerTokens = await checkOrUpdateActiveToken();

            if (customerTokens.activeToken && customerTokens.activeFingerprint && customerTokens.refreshToken) {

                const customer = (await useApiFetch(`/api/customer/me?currencyId=${locale.currencyId}&languageId=${locale.languageId}`))?.customer;

                if (!customer) {
                    logout();
                    return;
                }

                currentCustomer().value = customer;

                const customerDataCookie = useCookie('customerData');
                const selectedCountryCookie = useCookie('selectedCountryId');
                const selectedBillingCookie = useCookie('selectedBillingCountryId');

                const customerData = {
                    email: customer.email,
                    phone: customer.phone,
                    address: {
                        name: customer.shippingAddress?.name,
                        company: customer.shippingAddress?.company,
                        street: customer.shippingAddress?.street,
                        city: customer.shippingAddress?.city,
                        zip: customer.shippingAddress?.zip,
                    },
                    billing: {
                        name: customer.billingAddress?.name,
                        company: customer.billingAddress?.company,
                        street: customer.billingAddress?.street,
                        city: customer.billingAddress?.city,
                        zip: customer.billingAddress?.zip,
                    },
                    shippingCountryId: customer.shippingAddress?.country?.id,
                    ic: customer.ic,
                    vatId: customer.dic,
                    companyChecked: !!customer.billingAddress?.company,
                    vatPayerChecked: !!customer.dic,
                    registerChecked: false,
                    billingAddressChecked: !!customer.billingAddress?.name,
                    delayedDispatchChecked: false,
                    delayedDispatchDate: null,
                    noteChecked: false,
                    note: '',
                    rejectReviewChecked: false,
                    newsletterChecked: true,
                };

                customerDataCookie.value = customerData;

                // set selected country in case it has not been selected in the shipping page yet and if the shopZone does not have countryIds set
                if (!selectedCountryCookie.value && customer.shippingAddress?.country && !shopZone.countryIds) {
                    selectedCountryCookie.value = customer.shippingAddress.country.id;
                }

                // billing is manageable directly in the addresses page, so update it always
                if (customer.billingAddress?.country) {
                    selectedBillingCookie.value = customer.billingAddress.country.id;
                }
            }
        }
    }

    const me = () => computed(() => currentCustomer().value.shippingAddress?.name);

    const isLogged = () => currentCustomer().value !== null;

    const logout = async () => {
        useCookie('activeToken').value = undefined;
        useCookie('refreshToken').value = undefined;
        useCookie('activeFingerprint').value = undefined;
        useCookie('refreshFingerprint').value = undefined;
        useCookie('customerData').value = undefined;
        currentCustomer().value = null;

        await useCart().clear();
    }

    return {
        currentCustomer,
        initializeCustomer,
        checkOrUpdateActiveToken,
        me,
        isLogged,
        login,
        logout
    }
}