export const useConsent = () => {

  const setConsent = (value) => {

    const isInitialSetting = getConsent().value == null;

    useState('consent').value = value;
    updateCMP(isInitialSetting);
  }

  const getConsent = () => {
    const result = useState('consent', () => useCookie('cookieConsent').value);

    return result;
  }

  const hasConsent = (categoryMask: Number) => {
    const consent = getConsent().value || 0;

    const result = (consent & categoryMask) === categoryMask;

    return result;
  }

  const updateCMP = (isInitialSetting) => {
    gtag(
      'consent',
      'update',
      {
        'analytics_storage': hasConsent(1) ? 'granted' : 'denied',
        'ad_storage': hasConsent(2) ? 'granted' : 'denied',
        'ad_user_data': hasConsent(4) ? 'granted' : 'denied',
        'ad_personalization': hasConsent(4) ? 'granted' : 'denied',
      }
    );

    if (isInitialSetting) {
      setTimeout(() => {
        useBaseAnalytics().pushEvent('consentGranted');
      }, 100);
    }
  }

  const propagateDefaultCMP = () => {
    gtag(
      'consent',
      'default',
      {
        'analytics_storage': 'denied',
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
      }
    );
  }

  return {
    setConsent,
    getConsent,
    hasConsent,
    updateCMP,
    propagateDefaultCMP
  }

}